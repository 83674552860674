<template>
<Menu as="div" class="relative inline-block text-left">
    <div>
        <MenuButton id="faq"
            class="inline-flex w-full justify-center gap-x-1.5 pe-1 py-2 text-sm font-semibold hover:transparent">
            <img src="@/assets/icons/utilities/help.svg" alt="Ajuda" class="w-6 h-6 cursor-pointer" />
        </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
                <!-- <MenuItem v-slot="{ active }">
                <a href="#" :class="[
                    active
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                ]" @click="fazerTourNovamente">Ver dicas da página</a>
                </MenuItem> -->
                <MenuItem v-slot="{ active }">
                <a href="/faq" :class="[
                    active
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                ]">FAQ - Perguntas frequentes</a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                <a href="https://wa.me/message/V5F3OEYJPVAYF1" :class="[
                    active
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                ]" target="_blank">WhatsApp</a>
                </MenuItem>
            </div>
        </MenuItems>
    </transition>
</Menu>
</template>

<script setup>
import { inject, onMounted } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { useStartupStore } from "@/stores/startup.store"
import { useRoute } from 'vue-router'

const startupStore = useStartupStore();
const route = useRoute();
const initialTourShpherd = inject("initialTour"); // Rota /acao

const fazerTourNovamente = () => {
    console.log(route.path);
    const path = route.path;

    switch (path) {
        case '/acao':
            initialTourShpherd.start();
            break;

        default:
            break;
    }
};

const eventoConfirmacaoUsuarioEncerramentoDoTour = (path) => {
    switch (path) {
        case '/acao':
            finalizarInitialTour(path);
            break;

        default:
            break;
    }
};

const finalizarInitialTour = (path) => {
    const modalTourIntroducao = document.querySelector('.initial-tour');

    if (modalTourIntroducao) {
        const botaoFecharTourIntroducao = modalTourIntroducao.querySelector('.shepherd-cancel-icon');

        if (botaoFecharTourIntroducao) {
            botaoFecharTourIntroducao.addEventListener('click', () => { startupStore.finalizarTour(path); });
        }

        const botaoPularTourIntroducao = modalTourIntroducao.querySelector('footer > button.shepherd-button');

        if (botaoPularTourIntroducao) {
            botaoPularTourIntroducao.addEventListener('click', () => { startupStore.finalizarTour(path); });
        }
    }
};

onMounted(() => {
    const path = route.path;

    switch (path) {
        case '/acao':
            if (!startupStore.tourInicialRealizado) {
                initialTourShpherd.start();
                eventoConfirmacaoUsuarioEncerramentoDoTour(path);
            }
            break;
    }
})

</script>
