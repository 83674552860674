<template>
<div class="mx-[28px]" v-if="loading">
    <div class="text-center my-2">
        <h1 class="font-bold-segoe text-[#FD7862] text-[26px] mb-3">Progresso</h1>
        <p class="text-[#FD7862] font-bold-segoe text-[15px]">Seu Compromisso neste momento:</p>
        <p class="text-[#FD7862] font-bold-segoe text-[13px] mb-3">
            <span v-if="user.frequence <= 1">É</span><span v-else>São</span> de {{ user.frequence }} <span
                v-if="user.frequence <= 1">ação</span>
            <span v-else>ações</span> por semana.
        </p>
        <span class="text-[13px] text-[#727376] font-italic-segoe">
            Você realizou
            <strong>{{ quantidadeAcoes }} <span v-if="quantidadeAcoes <= 1">ação</span> <span v-else>ações</span> nessa
                semana</strong>.
        </span>
    </div>
    <div class="flex flex-col items-center justify-center">
        <img src="@/assets/icons/avatars/grafico.svg" alt="" class="mb-2 size-[90%]">
        <img src="@/assets/icons/arrows/arrow-right.svg" alt="" class="mb-2 size-[90%]">
    </div>
    <div class="flex flex-col items-center justify-center">
        <router-link to="/diario-acoes"
            class="bg-[#FD7062] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-[10px] text-center">
            diário de ações de bem-estar
        </router-link>
        <router-link to="/diario-fatos"
            class="bg-[#FD7062] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-[10px] text-center">
            diário de experiências pessoais
        </router-link>
        <router-link to="/diario-perguntas"
            class="bg-[#FD7062] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-[10px] text-center">
            reflexões
        </router-link>
        <router-link to="/historico-bem-estar"
            class="bg-[#FD7062] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-[10px] text-center">
            diagnóstico do bem-estar
        </router-link>


        <!-- <button
            class="bg-[#FD7062] w-[95%] text-white font-bold-segoe text-[13px] p-3 m-1 rounded-[10px] text-center cursor-not-allowed">
            <LockClosedIcon class="text-center size-4 align-top me-1" style="display: inline;" /> diagnóstico do
            bem-estar
        </button> -->
    </div>
</div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { usePerfil } from '@/modules/progresso/composables/usePerfil';
    import { LockClosedIcon } from '@heroicons/vue/24/solid'

    const { user, loading, quantidadeAcoes, fetchPerfil } = usePerfil();

    onMounted(() => {
        fetchPerfil();
    });
</script>

<style scoped></style>
