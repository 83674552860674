import axios from 'axios';
const API_URL = `${process.env.VUE_APP_API_URL}`;

export default {
    async findById(id) {
        if (!id) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {
            const url = `${API_URL}/user-question-answer/find-by-id/${id}`;
            const response = await axios.get(url);
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },
    async findByUserId(userId) {
        if (!userId) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {
            const url = `${API_URL}/user-question-answer/${userId}`;
            const response = await axios.get(url);
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    },
    async update(userQuestionAnswerId, overwallPercentage) {
        if (!userQuestionAnswerId || !overwallPercentage) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {
            const url = `${API_URL}/user-question-answer/${userQuestionAnswerId}`;
            const response = await axios.patch(url, { overwallPercentage });
            return response.data;
        }
        catch (error) {
            console.error('Erro ao atualizar dados:', error);
            throw error;
        }
    },
    async getUserRecentResults(userId) {
        if (!userId) {
            throw new Error('Parâmetros obrigatórios não informados');
        }

        try {
            const url = `${API_URL}/user-question-answer/get-recent-results/${userId}`;
            const response = await axios.get(url);
            return response.data;
        }
        catch (error) {
            console.error('Erro ao obter dados:', error);
            throw error;
        }
    }
}
