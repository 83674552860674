<template>
<div class="mx-[28px]" v-if="loading">
    <div class="text-center my-4">
        <h1 class="font-bold-segoe text-[#FD7862] text-[26px] mb-3 leading-7">Diário de<br /> reflexões</h1>
        <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[15px]">
            O diário de reflexões é uma excelente oportunidade para você perceber sua capacidade de pensar por si mesmo.
            Além disso, ele ajuda a aprimorar suas habilidades de expressão, tornando-o mais articulado e influente.
        </p>
    </div>

    <div class="flex flex-col items-center justify-center mb-6">
        <div v-for="ask in user.asktoGo" :key="ask.idAsk" class="mb-1 w-full">
            <div class="w-full bg-[#FED2CD] border-[#FD7062] border rounded-[13px] p-3 m-1 text-center">
                <h1 class="text-[#FD5445] text-sm font-bold-segoe">{{ ask.textContent }}</h1>
                <p class="text-[#FD5445] text-sm">{{ ask.textAnswer }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { usePerfil } from '@/modules/progresso/composables/usePerfil';

    const { user, loading, fetchPerfil } = usePerfil();

    onMounted(() => {
        fetchPerfil();
    });
</script>

<style scoped></style>
