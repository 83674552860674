<template>
<div>
    <div id="animacao" class="flex items-center justify-center my-5">
        <div id="circulo-branco"
            class="border h-[220px] w-[220px] rounded-full shadow-[-10px_-10px_30px_4px_rgba(139,199,87,0.5),_10px_10px_30px_4px_rgba(139,199,87,0.5)] flex items-center justify-center">
            <div class="h-[200px] w-[200px] rounded-full border-[#8bc757] border flex items-center justify-center">
                <div class="h-[195px] w-[195px] rounded-full bg-[#8bc757] flex items-center justify-center">
                    <img v-if="!ativado" src="@/assets/icons/avatars/silencio.png" alt="">
                    <div v-if="ativado" class="countdown-bar">
                        <div class="progress" :style="{ '--progress': progressPercentage + '%' }"></div>
                        <div
                            class="countdown-text text-white h-[195px] w-[195px] rounded-full bg-[#8bc757] flex items-center justify-center">
                            {{ countdown }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-col items-center justify-center text-center mx-[60px]">
        <h1 v-if="!ativado" class="font-bold-segoe text-[#8BC757] text-[26px] leading-6 mb-3">
            Cronômetro <br />
            do Silêncio
        </h1>
        <h1 v-if="ativado" class="font-bold-segoe text-[#8BC757] text-[1.5rem] leading-6 my-7">
            Minutos ativados
        </h1>
        <p v-if="!ativado" class="text-[#8BC757] text-[15px] font-bold-segoe leading-5 mb-2">
            Estar em silêncio é respeito ao
            seu corpo, mente e espírito.
        </p>
        <p v-if="!ativado" class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[20px]">
            Muitas vezes estar em silêncio é desconfortável, porém
            esse desconforto pode representar um espaço de
            construção interna importante no processo de
            desenvolvimento.
        </p>
        <div v-if="ativado" class="flex flex-col gap-2 my-7">
            <p class="mt-[40px] text-[15px] text-[#8BC757] font-segoe-ui flex gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4 mt-1">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                Horário de Ativação {{ horaInicio }}
            </p>
            <p class="mb-[40px] text-[15px] text-[#8BC757] font-segoe-ui flex gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-4 h-4 mt-1">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                Horário de Término {{ horaTermino }}
            </p>
        </div>
    </div>
    <div v-if="!ativado" class="my-5 flex gap-10 justify-center items-center">
        <button
            class="border border-[#8BC757] rounded-[10px] h-[40px] w-[40px] text-[#8BC757] text-[20px] flex items-center justify-center font-bold-segoe"
            @click="less">-</button>
        <div class="flex items-center justify-center">
            <FormKit type="number" number="integer" step="1" name="repeticao" min=1 :value="qtde"
                :classes="config_select" :disabled="true" v-model="qtde" />
            <span class="p-5 text-center text-[#8BC757] font-bold-segoe">{{
                qtdeComTexto }}</span>
        </div>
        <button
            class="border border-[#8BC757] rounded-[10px] h-[40px] w-[40px] text-[#8BC757] text-[20px] flex items-center justify-center font-bold-segoe"
            @click="more">+</button>
    </div>
    <div class="flex items-center justify-center">
        <button v-if="!ativado" class="font-bold-segoe bg-[#8BC757] outline-none border-none
      text-[#FFF] text-[14px] p-3 rounded-[13px] w-[90%] " @click="iniciarSilencio">
            ativar silêncio
        </button>
        <button v-if="ativado" class="font-bold-segoe bg-[#8BC757] outline-none border-none
      text-[#FFF] text-[14px] p-3 rounded-[13px] w-[90%] " @click="pararSilencio">
            parar silêncio
        </button>
    </div>
</div>
</template>

<script setup>
    import { useCronometro } from '@/modules/silencio/composables/useCronometro';

    const { qtde, ativado, progressPercentage, countdown, horaInicio, horaTermino, qtdeComTexto, config_select, less, more, iniciarSilencio, pararSilencio } = useCronometro();
</script>

<style scoped>
    .countdown-bar {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .progress {
        width: 220px;
        height: 220px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: conic-gradient(#b5e38c var(--progress), #FFF 0%);
        border-radius: 50%;
        transform-origin: center;
    }

    .countdown-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        color: #fff;
        font-weight: bold;
    }
</style>
