<template>
<CustomModal v-model="showPrivacyPolicyModal" title="Hooy. Respeito a sua privacidade." @confirm="confirm"
    :modalClass="['flex justify-center items-center bg-[rgba(0,0,0,0.5)]']"
    :contentClass="['text-[#727376] bg-[#FEEEE2] border border-[#F37334] flex flex-col max-w-xl m-4 p-4 rounded-lg space-y-2']"
    :titleClass="['text-[#F37334] font-bold text-center mb-4 text-xl font-bold-segoe']">
    <p class="text-center">
        As suas <strong>respostas</strong>, informações e
        dados pessoais, <strong>são criptografadas</strong>
        apenas você consegue visualizar.
    </p>
</CustomModal>

<Loader :loading="loading" />

<div class="flex flex-col min-h-screen" v-tour-step:1="steps[0]">
    <header>
        <Navbar />
        <BannerInicial :logoHooy="colorNameStore" :borderColor="colorNameStore" />
    </header>
    <main class="flex-grow flex flex-col justify-between">
        <router-view @loading="handleLoader" />
    </main>
    <footer class="mt-auto">
        <Footer :iconVoltar="colorNameStore" :miniLogo="colorNameStore"></Footer>
    </footer>
</div>
</template>

<script setup>
    import { ref, onBeforeMount, inject, watch, onMounted, computed } from 'vue';
    import { useRouter } from 'vue-router';
    import Navbar from '@/components/Navbar/Navbar.vue';
    import BannerInicial from '@/components/Banners/BannerInicial.vue';
    import Footer from '@/components/Footers/FooterInicial.vue';
    import Loader from '@/modules/acao/components/Loader.vue';
    import CustomModal from '@/components/Modals/CustomModal.vue';
    import createTourSteps from "@/utilities/custom-tour/initialTour/initial-tour";
    import { useStartupStore } from "@/stores/startup.store";
    import { useAuthStore } from "@/store";
    import { useCustomStyleStore } from '@/stores/customStyle.store';

    // Estado de controle
    const startupStore = useStartupStore();
    const auth = useAuthStore();
    const initialTour = inject("initialTour");
    const steps = createTourSteps(initialTour);
    const showPrivacyPolicyModal = ref(false);
    // const color = ref('orange');
    const loading = ref(false);
    const router = useRouter();
    const customStyleStore = useCustomStyleStore();

    // Computed para queryParams
    const queryParams = computed(() => router.currentRoute.value.query);
    const colorNameStore = computed(() => customStyleStore.colorName);

    // Funções
    const handleLoader = () => {
        loading.value = !loading.value;
    };

    const confirm = () => {
        auth.setAcceptedPolicy(true);
        if (showPrivacyPolicyModal.value && !startupStore.tourInicialRealizado) {
            setTimeout(() => {
                initialTour.start();
            }, 2000);
        }
    };

    // Watchers
    watch(() => auth.acceptedPolicy, (novoEstado) => {
        if (novoEstado) {
            showPrivacyPolicyModal.value = false;
        }
    });

    // watch(() => router.currentRoute.value.path, () => {
    //     color.value = 'orange';
    // });

    // // Ciclos de vida
    // onBeforeMount(() => {
    //     color.value = 'orange';
    // });

    onMounted(() => {
        if (!auth.acceptedPolicy && !startupStore.tourInicialRealizado) {
            showPrivacyPolicyModal.value = true;
            initialTour.hide();
        }
    });
</script>
